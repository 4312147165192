import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout/layout'

const NotFoundPage = () => (
  <Layout>
    <div id="main">
      <section id="abstract">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="global-page-header text-center">
                <div className="clearfix">
                  <h2>Page not found :(</h2>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="line-bottom text-center">
                <Link to="/" className="btn btn-primary">
                  Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default NotFoundPage
